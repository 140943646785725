import { AuthenticatedUser } from '../models/authenticatedUser';
import { GlobalResourceMetadata, Resource } from '../models/contentModels';

export const hasAdministratorPrivilege = (
    connectedUser: AuthenticatedUser | null,
) => {
    return connectedUser && connectedUser.privileges === 'Administrator';
};

export const canCreateResource = (connectedUser: AuthenticatedUser | null) => {
    return canCreateOrUpdateResource(connectedUser);
};

export const hasCreationRight = (connectedUser: AuthenticatedUser | null) => {
    return (
        canCreateUserResourcePrivilege(connectedUser) ||
        hasAdministratorPrivilege(connectedUser) ||
        canCreateResource(connectedUser)
    );
};

export const canEditResource = (
    connectedUser: AuthenticatedUser | null,
    metadata: GlobalResourceMetadata | Resource | null,
) => {
    return (
        hasAdministratorPrivilege(connectedUser) ||
        canCreateOrUpdateResource(connectedUser) ||
        (metadata && metadata.isOwner) ||
        (metadata && metadata.isWriter)
    );
};

const canCreateOrUpdateResource = (connectedUser: AuthenticatedUser | null) => {
    return connectedUser && connectedUser.canCreateOrUpdateResource;
};

const canCreateUserResourcePrivilege = (
    connectedUser: AuthenticatedUser | null,
) => {
    return connectedUser && connectedUser.privileges !== 'No privileges';
};
