export const configPrd = {
    environment: '',
    sgConnect: {
        authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect',
        unauthorizationEndpoint: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://content.sgmarkets.com',
        clientId: 'c1e70201-db6b-4390-9233-b6ee5d5d27da',
        scope: 'openid profile mail api.insightuserdomain.v1 api.manage-contacts.v1 api.researchcontentdomain.admin api.researchcontentdomain.user api.researchcontentdomain.v1',
        level: 'L1',
    },
    apiContentUrl: 'https://pretrade-api-content.fr.world.socgen',
    apiContentRequestSizeLimitInMB: 28,
    serveContentUrl: 'https://content.sgmarkets.com',
    kibanaUrl: 'https://97c8aba440514e86bee255d620fd4453.ece.sgmonitoring.prd.euw.gbis.sg-azure.com',
    widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
    sharedSgMarketsBaseUrl: 'https://shared.sgmarkets.com',
};
