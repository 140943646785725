import React, { PropsWithChildren } from 'react';
import { getConfiguration } from '../../core/configuration/configurationLoader';
import { fetcher } from '../../core/http/fetcher';
import { logTechnical } from '../../core/logging/logger';
import _ from 'lodash';
import {
    Resource,
    Filters,
    CountResponse,
    SearchResourcesResponse,
    AccessLevelsResponse,
    MimeTypesResponse,
    CategoriesResponse,
    OwnersResponse,
} from '../../core/models/contentModels';

type FiltersGroup = { [key: string]: Filters[] };

const defaultSelectedFilters = (): { [key: string]: string[] } => {
    return {
        'Access Level': [],
        Categories: [],
        'Mime-Types': [],
        Owners: [],
    };
};

const initialState = {
    count: 0,
    isLoading: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateSearch: (filename: string) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updatePager: (skip: number) => { },
    updateFilter: () => { },
    hasAvailableFilters: () => false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChangeSelectedFilter: (filterGroup: string, id: string): any => { },
    skip: 0,
    take: 20,
    search: '',
    resources: [] as Resource[],
    availableFilters: {} as FiltersGroup,
    selectedFilters: defaultSelectedFilters(),
};

type SearchResourcesProviderState = Readonly<typeof initialState>;

export type SearchResourcesContextState = SearchResourcesProviderState;

const context = React.createContext<SearchResourcesContextState>(initialState);
const { Provider, Consumer } = context;

class SearchResourcesProvider extends React.Component<
    PropsWithChildren,
    SearchResourcesProviderState
> {
    public readonly state: SearchResourcesProviderState = initialState;
    private searchDebounceTimeout: NodeJS.Timeout | undefined;

    constructor(props: any) {
        super(props);
        this.updateSearch = this.updateSearch.bind(this);
        this.search = this.search.bind(this);
        this.searchAndUpdateFilter = this.searchAndUpdateFilter.bind(this);
        this.updateSearchDebounce = this.updateSearchDebounce.bind(this);
        this.updatePager = this.updatePager.bind(this);
        this.onChangeSelectedFilter = this.onChangeSelectedFilter.bind(this);
        this.hasAvailableFilters = this.hasAvailableFilters.bind(this);
    }

    public componentDidMount() {
        this.setState(
            {
                updateSearch: this.updateSearch,
                updatePager: this.updatePager,
                onChangeSelectedFilter: this.onChangeSelectedFilter,
                hasAvailableFilters: this.hasAvailableFilters,
            },
            () => {
                this.searchAndUpdateFilter();
            },
        );
    }

    public render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }

    private hasAvailableFilters(): boolean {
        return _.some(this.state.availableFilters, k => k.length > 0);
    }

    private updatePager(skip: number) {
        this.setState({ skip }, this.forceUpdateSearchDebounce);
    }

    private forceUpdateSearchDebounce() {
        if (this.searchDebounceTimeout) {
            clearTimeout(this.searchDebounceTimeout);
        }
        this.searchDebounceTimeout = setTimeout(() => {
            this.search();
        }, 0);
    }

    private updateSearchDebounce() {
        if (this.searchDebounceTimeout) {
            clearTimeout(this.searchDebounceTimeout);
        }
        this.searchDebounceTimeout = setTimeout(() => {
            this.setState({ skip: 0 }, this.searchAndUpdateFilter);
        }, 1000);
    }

    private updateSearch(filename: string) {
        this.setState({ search: filename }, this.updateSearchDebounce);
    }

    private async searchCount() {
        const { apiContentUrl } = getConfiguration();
        try {
            const filter = transformFilterGroupToQuery(
                this.state.selectedFilters,
            );
            const userInformation = await fetcher<CountResponse>(
                `${apiContentUrl}/api/v2/frontend/search/resources/count?partialName=${this.state.search
                }${filter ? `&${filter}` : ''}`,
                'GET',
            );

            this.setState(updateSearchCount(userInformation.count));
        } catch (error: any) {
            logTechnical('error', error.message, { stack: error.stack || '' });
            this.setState({ count: 0 });
        }
    }

    private async searchResources() {
        const { apiContentUrl } = getConfiguration();
        try {
            const filter = transformFilterGroupToQuery(
                this.state.selectedFilters,
            );
            const resourceResponse = await fetcher<SearchResourcesResponse>(
                `${apiContentUrl}/api/v2/frontend/search/resources?pageNumber=${this.state.skip
                }&pageSize=${this.state.take}&partialName=${this.state.search}${filter ? `&${filter}` : ''
                }`,
                'GET',
            );

            this.setState(updateSearchResource(resourceResponse.resources));
        } catch (error: any) {
            logTechnical('error', error.message, { stack: error.stack || '' });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    private async searchFilters() {
        const { apiContentUrl } = getConfiguration();
        try {
            const accessLevelFiltersResponse = fetcher<AccessLevelsResponse>(
                `${apiContentUrl}/api/v2/frontend/search/access-levels?partialName=${this.state.search}`,
                'GET',
            );

            const categoriesFiltersResponse = fetcher<CategoriesResponse>(
                `${apiContentUrl}/api/v2/frontend/search/categories?partialName=${this.state.search}`,
                'GET',
            );

            const mimetypeFiltersResponse = fetcher<MimeTypesResponse>(
                `${apiContentUrl}/api/v2/frontend/search/mime-types?partialName=${this.state.search}`,
                'GET',
            );
            const ownerFiltersResponse = fetcher<OwnersResponse>(
                `${apiContentUrl}/api/v2/frontend/search/owners?partialName=${this.state.search}`,
                'GET',
            );

            this.setState({
                availableFilters: {
                    'Access Level': (await accessLevelFiltersResponse)
                        .accessLevels,
                    Categories: (await categoriesFiltersResponse).categories,
                    'Mime-Types': (await mimetypeFiltersResponse).mimeTypes,
                    Owners: (await ownerFiltersResponse).owners.map(x => ({
                        id: x.ownerIcId,
                        name: x.name,
                        count: x.count,
                    })),
                },
            });
        } catch (error: any) {
            logTechnical('error', error.message, { stack: error.stack || '' });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    private onChangeSelectedFilter(filterGroup: string, id: string) {
        return (event: React.SyntheticEvent<HTMLInputElement>) => {
            const selectedFilter = _.clone(
                this.state.selectedFilters[filterGroup] || [],
            );
            const checked = event.currentTarget.checked;
            if (checked) {
                selectedFilter.push(id);
            } else {
                _.remove(selectedFilter, n => n === id);
            }
            this.setState(
                updateSelectedFilters(filterGroup, _.uniq(selectedFilter)),
                this.forceUpdateSearchDebounce,
            );
        };
    }

    private searchAndUpdateFilter() {
        this.searchFilters();
        this.search();
    }

    private search() {
        this.searchCount();
        this.searchResources();
    }
}

const transformFilterGroupToQuery = (group: { [key: string]: string[] }) => {
    const transformContent = [
        { groupName: 'Access Level', queryParams: 'accessLevelIds' },
        { groupName: 'Categories', queryParams: 'categoryIds' },
        { groupName: 'Mime-Types', queryParams: 'mimeTypes' },
        { groupName: 'Owners', queryParams: 'ownerIds' },
    ];
    const resultArray = [] as string[];
    for (const content of transformContent) {
        const transform = transformNumberArrayToArrayQuery(
            group[content.groupName],
        );
        if (transform) {
            resultArray.push(`${content.queryParams}=${transform}`);
        }
    }
    return resultArray.join('&');
};

const transformNumberArrayToArrayQuery = (array: string[]) => {
    return array.join(',') || '';
};

const updateSearchCount = (count: number) => (
    prevState: SearchResourcesContextState,
): SearchResourcesContextState => ({
    ...prevState,
    count,
});

const updateSearchResource = (resources: Resource[]) => (
    prevState: SearchResourcesContextState,
): SearchResourcesContextState => ({
    ...prevState,
    resources,
});

const updateSelectedFilters = (name: string, value: string[]) => (
    prevState: SearchResourcesContextState,
): SearchResourcesContextState => ({
    ...prevState,
    selectedFilters: { ...prevState.selectedFilters, [name]: value },
});

export {
    SearchResourcesProvider,
    Consumer as SearchResourcesConsumer,
    context as SearchResourcesContext,
};
