import * as React from 'react';
import { SearchResourcesContext } from '../../contexts/SearchResourcesContext';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import {
    hasAdministratorPrivilege,
    hasCreationRight,
} from '../../../core/service/ConnectedUserPrivilege';

type NavigationProps = {
    title: string;
    hideCreateButton?: boolean;
    selectedPills?: string;
};

export const Navigation = (props: NavigationProps) => {
    const userContext = React.useContext(AuthenticationContext);
    const resourceContext = React.useContext(SearchResourcesContext);

    const canCreate = hasCreationRight(userContext.authenticatedUser);
    const isAdmin = hasAdministratorPrivilege(userContext.authenticatedUser);

    return (
        <div className="pt-5 border-bottom">
            <div className="container">
                <div className="row d-flex">
                    <div className="col-lg-8 mb-3 mb-lg-0">
                        <h1 className="display-3 m-0">{props.title}</h1>
                    </div>

                    {!props.hideCreateButton && canCreate ? (
                        <div className="col-lg-4 d-lg-flex align-items-center justify-content-end">
                            <a
                                href="/create"
                                className="btn btn-primary btn-lg btn-icon-text mr-3 mr-lg-0"
                            >
                                <i className="icon">add</i> Create Resource
                            </a>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <ul
                    className="nav nav-pills text-large mt-3 row"
                    role="tablist"
                >
                    <li className="nav-item">
                        <a
                            className={`nav-link pt-3 ${
                                props.selectedPills === 'Resources'
                                    ? 'active show'
                                    : ''
                            }`}
                            href="/"
                        >
                            Resources{' '}
                            {props.selectedPills === 'Resources' &&
                                resourceContext &&
                                !resourceContext.isLoading && (
                                    <span className="badge badge-pill badge-primary ml-2">
                                        {resourceContext.count}
                                    </span>
                                )}
                        </a>
                    </li>
                    {isAdmin ? (
                        <>
                            <li className="nav-item">
                                <a
                                    className={`nav-link pt-3 ${
                                        props.selectedPills === 'Dashboard'
                                            ? 'active show'
                                            : ''
                                    }`}
                                    href="/dashboard"
                                >
                                    Dashboard
                                </a>
                            </li>
                        </>
                    ) : null}
                    {!props.selectedPills ? (
                        <li className="nav-item">
                            <a className="nav-link active show pt-3" href="#/">
                                {props.title}
                            </a>
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};
