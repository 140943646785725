import React, { PropsWithChildren } from 'react';
import { PersonIc, ResourceMetadata } from '../../core/models/contentModels';
import { sendForm, HttpError } from '../../core/http/fetcher';
import { getConfiguration } from '../../core/configuration/configurationLoader';
import { logTechnical } from '../../core/logging/logger';

const initialState = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateResourceType: (value: number) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleInputChange: (elt: React.FormEvent<HTMLInputElement>) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleSelectChange: (elt: React.FormEvent<HTMLSelectElement>) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeWritersListener: (event: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeOwnersListener: (event: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeReadersListener: (event: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleFileChange: (event: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleDateFromFilterChange: (event: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleSubmit: (event: any): Promise<void> => Promise.resolve(),
    createResourceSuccess: 0,
    dataCreateError: '',
    resourceType: 1,
    id: 0,
    shortName: '',
    description: '',
    owners: [] as string[],
    writers: [] as string[],
    readers: [] as string[],
    endDate: new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth(),
        new Date().getDay(),
        new Date().getHours(),
        new Date().getMinutes(),
    ) as Date | null,
    updateDate: null as Date | null,
    confidentialityLevel: '',
    availableOutsideSgNetwork: 'false',
    accessLevelId: 10,
    categoryId: 1,
    isCacheable: false,
    file: React.createRef<any>(),
    fileSizeExceeded: false,
};

type CreateResourceProviderState = Readonly<typeof initialState>;

type CreateResourceProviderProps = { metadata?: ResourceMetadata };

export type CreateResourceContextState = CreateResourceProviderState;

const context = React.createContext<CreateResourceContextState>(initialState);
const { Provider, Consumer } = context;

const redirectToUserResource = () => {
    window.location.href = '/';
};

class CreateResourceProvider extends React.Component<
    PropsWithChildren<CreateResourceProviderProps>,
    CreateResourceProviderState
> {
    public readonly state: CreateResourceProviderState = initialState;

    constructor(props: CreateResourceProviderProps) {
        super(props);
        this.updateResourceType = this.updateResourceType.bind(this);
        this.handleDateFromFilterChange = this.handleDateFromFilterChange.bind(
            this,
        );
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.changeWritersListener = this.changeWritersListener.bind(this);
        this.changeOwnersListener = this.changeOwnersListener.bind(this);
        this.changeReadersListener = this.changeReadersListener.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public componentDidMount() {
        this.setState({
            updateResourceType: this.updateResourceType,
            handleDateFromFilterChange: this.handleDateFromFilterChange,
            handleInputChange: this.handleInputChange,
            handleSelectChange: this.handleSelectChange,
            changeWritersListener: this.changeWritersListener,
            changeOwnersListener: this.changeOwnersListener,
            changeReadersListener: this.changeReadersListener,
            handleFileChange: this.handleFileChange,
            handleSubmit: this.handleSubmit,
        });
        if (this.props.metadata) {
            const metadata = this.props.metadata;
            this.setState({
                resourceType: metadata.shortName.startsWith('DOC_') ? 1 : 0,
                id: metadata.id,
                shortName: metadata.shortName,
                description: metadata.description,
                owners: metadata.owners
                    ? metadata.owners
                    : initialState.owners,
                writers: metadata.writers,
                endDate: metadata.endDate
                    ? new Date(metadata.endDate)
                    : initialState.endDate,
                accessLevelId: metadata.accessLevelId
                    ? getAccessLevelId(metadata.accessLevelId)
                    : initialState.accessLevelId,
                categoryId: metadata.categoryId
                    ? metadata.categoryId
                    : initialState.categoryId,
                isCacheable: metadata.isCacheable
                    ? metadata.isCacheable
                    : initialState.isCacheable,
                readers: metadata.readers
                    ? metadata.readers
                    : initialState.readers,
                confidentialityLevel: metadata.confidentialityLevel
                    ? getConfidentialityLevel(metadata.confidentialityLevel)
                    : initialState.confidentialityLevel,
                availableOutsideSgNetwork: metadata.availableOutsideSgNetwork
                    ? `${metadata.availableOutsideSgNetwork}`
                    : initialState.availableOutsideSgNetwork,
            });
        }
    }

    public render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }

    private updateResourceType(value: number) {
        this.setState({ resourceType: value });
    }

    private handleDateFromFilterChange = (date: Date) => {
        this.setState({
            endDate: date,
        });
    };

    private handleInputChange = (elt: React.FormEvent<HTMLInputElement>) => {
        if (elt.currentTarget.id === 'inputShortname') {
            this.setState({
                shortName: elt.currentTarget.value,
            });
        } else if (elt.currentTarget.id === 'inputDescription') {
            this.setState({
                description: elt.currentTarget.value,
            });
        } else if (elt.currentTarget.id === 'checkUseCache') {
            this.setState({
                isCacheable: elt.currentTarget.checked,
            });
        }
    };

    private handleSelectChange = (elt: React.FormEvent<HTMLSelectElement>) => {
        if (elt.currentTarget.id === 'selectAccessLevel') {
            this.setState({
                accessLevelId: Number(
                    elt.currentTarget.selectedOptions[0].value,
                ),
            });
        } else if (elt.currentTarget.id === 'selectCategory') {
            this.setState({
                categoryId: Number(elt.currentTarget.selectedOptions[0].value),
            });
        } else if (elt.currentTarget.id === 'selectConfidentialityLevel') {
            this.setState({
                confidentialityLevel:
                    elt.currentTarget.selectedOptions[0].value,
            });
        } else if (elt.currentTarget.id === 'selectAvailableOutsideSgNetwork') {
            this.setState({
                availableOutsideSgNetwork:
                    elt.currentTarget.selectedOptions[0].value,
            });
        }
    };

    private changeWritersListener: EventListener = (event: any) => {
        const fieldValue = event.detail.contacts.map(
            (icContact: PersonIc) => icContact.id,
        );

        this.setState({ writers: fieldValue });
    };

    private changeOwnersListener: EventListener = (event: any) => {
        const fieldValue = event.detail.contacts.map(
            (icContact: PersonIc) => icContact.id,
        );

        this.setState({ owners: fieldValue });
    };

    private changeReadersListener: EventListener = (event: any) => {
        const fieldValue = event.detail.contacts.map(
            (icContact: PersonIc) => icContact.id,
        );

        this.setState({ readers: fieldValue });
    };

    private handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const input = evt.currentTarget;
        const { apiContentRequestSizeLimitInMB } = getConfiguration();
        const apiSizeLimit = apiContentRequestSizeLimitInMB * 1024 * 1024; // maxAllowedContentLength in content api, default value : 30000000

        if (
            input.files &&
            input.files[0] &&
            input.files[0].size >= apiSizeLimit
        ) {
            this.setState({
                fileSizeExceeded: true,
                dataCreateError: `File size cannot exceed ${apiContentRequestSizeLimitInMB}MB`,
                createResourceSuccess: 2,
            });
        } else {
            this.setState({
                fileSizeExceeded: false,
                dataCreateError: '',
                createResourceSuccess: 0,
            });
        }
    };

    private handleSubmit = async (event: any) => {
        event.preventDefault();

        if (this.stateIsValid()) {
            if (this.state.resourceType === 0) {
                await this.createResource();
            } else {
                await this.createUserResource();
            }
        }
    };

    private stateIsValid = () => {
        return this.state.fileSizeExceeded === false;
    };

    private createResource = async () => {
        const formData = new FormData();
        formData.append('Id', `${this.state.id}`);
        formData.append('DataFile', this.state.file.current.files[0]);
        formData.append('ShortName', this.state.shortName);
        formData.append('Description', this.state.description);
        if (this.state.owners && this.state.owners.length > 0) {
            formData.append(
                'Owners',
                this.state.owners && this.state.owners.length > 0
                    ? this.state.owners.join(',')
                    : '',
            );
        }
        formData.append('CategoryId', `${this.state.categoryId}`);
        formData.append('AccessLevelId', `${this.state.accessLevelId}`);
        formData.append('IsCacheable', `${this.state.isCacheable}`);

        const { apiContentUrl } = getConfiguration();
        try {
            await sendForm<any>(
                `${apiContentUrl}/api/v2/frontend/do-add-resource`,
                'POST',
                formData,
            );
            this.setState(
                { dataCreateError: '', createResourceSuccess: 1 },
                redirectToUserResource,
            );
        } catch (error) {
            if (error as HttpError) {
                const httpError = error as HttpError;
                this.setState({
                    dataCreateError: transformHttpErrorToErrorMessage(
                        httpError,
                    ),
                    createResourceSuccess: 2,
                });
                logTechnical('error', httpError.message, {
                    stack: httpError.stack || '',
                });
            }
        }
    };

    private createUserResource = async () => {
        const formData = new FormData();
        formData.append('Id', `${this.state.id}`);
        formData.append('DataFile', this.state.file.current.files[0]);
        formData.append('ShortName', this.state.shortName);
        formData.append('Description', this.state.description);
        if (this.state.endDate) {
            formData.append('EndDate', this.state.endDate.toISOString());
        }
        formData.append(
            'AvailableOutsideSgNetwork',
            `${this.state.availableOutsideSgNetwork}`,
        );
        if (this.state.writers && this.state.writers.length > 0) {
            formData.append(
                'Writers',
                this.state.writers && this.state.writers.length > 0
                    ? this.state.writers.join(',')
                    : '',
            );
        }
        if (this.state.readers && this.state.readers.length > 0) {
            formData.append(
                'Readers',
                this.state.readers && this.state.readers.length > 0
                    ? this.state.readers.join(',')
                    : '',
            );
        }
        if (this.state.confidentialityLevel) {
            formData.append(
                'ConfidentialityLevel',
                this.state.confidentialityLevel,
            );
        }

        const { apiContentUrl } = getConfiguration();
        try {
            await sendForm<any>(
                `${apiContentUrl}/api/v2/frontend/do-add-user-resource`,
                'POST',
                formData,
            );
            this.setState(
                { dataCreateError: '', createResourceSuccess: 1 },
                redirectToUserResource,
            );
        } catch (error) {
            if (error as HttpError) {
                const httpError = error as HttpError;
                this.setState({
                    dataCreateError: transformHttpErrorToErrorMessage(
                        httpError,
                    ),
                    createResourceSuccess: 2,
                });
                logTechnical('error', httpError.message, {
                    stack: httpError.stack || '',
                });
            }
        }
    };
}

const getConfidentialityLevel = (level: string) => {
    switch (level) {
        case 'C0': {
            return '0';
        }
        case 'C1': {
            return '1';
        }
        default: {
            return '';
        }
    }
};

const getAccessLevelId = (level: string) => {
    switch (level) {
        case 'PUBLIC': {
            return 10;
        }
        case 'AUTHENTICATED_SGM_USERS': {
            return 20;
        }
        case 'AUTHENTICATED_INTERNAL_SGM_USERS': {
            return 30;
        }
        case 'USER': {
            return 45;
        }
        case 'SYSTEM': {
            return 50;
        }
        case 'NOACCESS': {
            return 60;
        }
        default: {
            return 10;
        }
    }
};

const transformHttpErrorToErrorMessage = (httpError: HttpError): string => {
    const defaultMessage = 'Please fill all fields';
    let errorMessage;

    if (httpError.statusCode === 400 && httpError.content.errors) {
        errorMessage = transformErrorMessageToString(httpError.content.errors);
    }

    const contentMessage = httpError.content ? httpError.content.message : null;
    return (
        errorMessage || contentMessage || httpError.message || defaultMessage
    );
};

const transformErrorMessageToString = (content: {
    [name: string]: string;
}): string => {
    const result = [];
    for (const [key, value] of Object.entries(content)) {
        result.push(`${key}: ${value}`);
    }
    return result.join(', ');
};

export {
    CreateResourceProvider,
    Consumer as CreateResourceConsumer,
    context as CreateResourceContext,
};
