import * as React from 'react';
import { CreateResourceContext } from '../../contexts/CreateResourceContext';
import { EditCreateResourceForm } from './EditCreateResourceForm';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import {
    hasAdministratorPrivilege,
    canCreateResource,
    hasCreationRight,
} from '../../../core/service/ConnectedUserPrivilege';
import UnauthorizedError from '../error/UnauthorizedError';

export const EditCreatePageLayout = () => {
    const context = React.useContext(CreateResourceContext);
    const userContext = React.useContext(AuthenticationContext);
    const handleChangeResourceType = React.useCallback(
        (event: any) => {
            context.updateResourceType(Number(event.currentTarget.value));
        },
        [context],
    );
    const canCreateResourceAllowed = canCreateResource(
        userContext.authenticatedUser,
    );
    const isAdmin = hasAdministratorPrivilege(userContext.authenticatedUser);
    const canCreateOrEdit =
        context.id !== 0 || hasCreationRight(userContext.authenticatedUser);
    const cannotSelectResourceType =
        context.id !== 0 || (!isAdmin && !canCreateResourceAllowed);

    return canCreateOrEdit ? (
        <div className="pt-5">
            <div className="container">
                {context.createResourceSuccess === 2 ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        Resource has not been{' '}
                        {context.id === 0 ? 'created' : 'updated'}:{' '}
                        {context.dataCreateError}
                    </div>
                ) : context.createResourceSuccess === 1 ? (
                    <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                    >
                        Resource has been{' '}
                        {context.id === 0 ? 'created' : 'updated'}.
                    </div>
                ) : (
                    <span />
                )}
                <h4 className="mb-3">
                    <span> Resource Type</span>
                </h4>
                <div className="row bg-lvl1 pt-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                <strong>
                                    Please choose the type of your resource
                                </strong>
                            </label>
                            <select
                                className="form-control"
                                id="selectConfidentialityLevel"
                                value={context.resourceType}
                                onChange={handleChangeResourceType}
                                disabled={cannotSelectResourceType}
                            >
                                <option value="0">Resource</option>
                                <option value="1">User Resource</option>
                            </select>
                        </div>
                    </div>
                </div>
                <EditCreateResourceForm />
            </div>
        </div>
    ) : (
        <UnauthorizedError />
    );
};
