import * as React from 'react';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { Navigation } from '../../common/navigation/Navigation';
import { SearchResourcesProvider } from '../../contexts/SearchResourcesContext';
import { SearchLayout } from './components/SearchLayout';
import { Loading } from '../../loading/Loading';

export const SearchPage = () => {
    const userContext = React.useContext(AuthenticationContext);

    if (userContext.authenticatedUser) {
        return (
            <SearchResourcesProvider>
                <Navigation title="Resources" selectedPills="Resources" />
                <SearchLayout />
            </SearchResourcesProvider>
        );
    }
    return <Loading />;
};
