import { CreateResourceContext } from '../../contexts/CreateResourceContext';
import classNames from 'classnames';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { getConfiguration } from '../../../core/configuration/configurationLoader';
import DatePicker from 'react-datepicker';
import React from 'react';
import { IcSinglePicker } from 'components/common/IcSinglePicker/IcSinglePicker';
import { IcMultiPicker } from 'components/common/IcMultiPicker/IcMultiPicker';

export const EditCreateResourceForm = () => {
    const context = React.useContext(CreateResourceContext);
    const { apiContentRequestSizeLimitInMB } = getConfiguration();

    const setWritersMountedElement = React.useCallback(
        (mountedElement: HTMLElement) => {
            if (mountedElement) {
                mountedElement.addEventListener(
                    'ic-contact-multi-picker--changed',
                    context.changeWritersListener,
                );
            }
        },
        [context],
    );

    const setOwnersMountedElement = React.useCallback(
        (mountedElement: HTMLElement) => {
            if (mountedElement) {
                mountedElement.addEventListener(
                    'ic-contact-multi-picker--changed',
                    context.changeOwnersListener,
                );
            }
        },
        [context],
    );

    const setReadersMountedElement = React.useCallback(
        (mountedElement: HTMLElement) => {
            if (mountedElement) {
                mountedElement.addEventListener(
                    'ic-contact-multi-picker--changed',
                    context.changeReadersListener,
                );
            }
        },
        [context],
    );

    const [isSumbmitting, setIsSubmitting] = React.useState(false);

    const handleSubmit = async (event: any) => {
        try {
            setIsSubmitting(true);
            await context.handleSubmit(event);
        }
        finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h4 className="mb-3 mt-5">
                    <span>General Informations</span>
                </h4>
                <div className="row bg-lvl1 mt-3 pt-3">
                    <div
                        className={
                            context.id === 0 ? 'col-md-12 d-none' : 'col-md-12'
                        }
                    >
                        <div className="form-group">
                            <label>
                                <strong>Owner</strong>
                            </label>
                            <IcSinglePicker id={context.owners[0]} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                <strong>Short Name</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputShortname"
                                disabled={context.id !== 0}
                                onChange={context.handleInputChange}
                                value={context.shortName}
                                placeholder="Short Name"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                <strong>Description</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputDescription"
                                onChange={context.handleInputChange}
                                placeholder="Description"
                                value={context.description}
                            />
                        </div>
                    </div>
                </div>
                {context.resourceType === 0 ? (
                    <EditCreateResourceGeneralInformation />
                ) : (
                    <EditCreateUserResourceGeneralInformation />
                )}
                <h4 className="mb-3 mt-5">
                    <span>Users Informations</span>
                </h4>
                <div className="row bg-lvl1 pt-3">
                    {context.resourceType === 1 ? (
                        <>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        <strong>Writers</strong>
                                    </label>
                                    <IcMultiPicker
                                        ref={setWritersMountedElement}
                                        selectedIds={context.writers && context.writers.length > 0
                                            ? JSON.stringify(context.writers)
                                            : '[]'}
                                        displayOption="jobtitle"
                                        placeholder="john.doe@sgcib.com"
                                        internalOnly="true"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        <strong>Readers</strong>
                                    </label>
                                    <IcMultiPicker
                                        ref={setReadersMountedElement}
                                        selectedIds={context.readers && context.readers.length > 0
                                            ? JSON.stringify(context.readers)
                                            : '[]'}
                                        displayOption="jobtitle"
                                        placeholder="john.doe@sgcib.com"
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    <strong>Owners</strong>
                                </label>
                                <IcMultiPicker
                                    ref={setOwnersMountedElement}
                                    selectedIds={context.owners && context.owners.length > 0
                                        ? JSON.stringify(context.owners)
                                        : '[]'}
                                    displayOption="jobtitle"
                                    placeholder="john.doe@sgcib.com"
                                    internalOnly="true"
                                />
                            </div>
                        </div>
                    )}
                </div>
                <h4 className="mb-3 mt-5">
                    <span>File</span>
                </h4>
                <div className="row bg-lvl1 pt-3">
                    <div className="col-md-6">
                        <div className="position-relative d-block">
                            <div className="form-group">
                                <input
                                    className={classNames({
                                        'text-danger': context.fileSizeExceeded,
                                    })}
                                    type="file"
                                    ref={context.file}
                                    onChange={context.handleFileChange}
                                />
                                <div
                                    className={classNames(
                                        { 'd-block': context.fileSizeExceeded },
                                        'invalid-feedback',
                                    )}
                                >
                                    File size cannot exceed{' '}
                                    {apiContentRequestSizeLimitInMB}MB
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <button disabled={isSumbmitting} type="submit" className="btn btn-success float-right">
                    {`${context.id === 0 ? 'Create' : 'Update'}${context.resourceType === 0 ? '' : ' User'
                        } Resource`}
                </button>
            </form>
        </div>
    );
};

const EditCreateUserResourceGeneralInformation = () => {
    const context = React.useContext(CreateResourceContext);
    const userContext = React.useContext(AuthenticationContext);
    return (
        <div className="row bg-lvl1">
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Confidentiality Level</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectConfidentialityLevel"
                        value={context.confidentialityLevel}
                        onChange={context.handleSelectChange}
                    >
                        <option value="">-</option>
                        <option value="0">C0</option>
                        <option value="1">C1</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>End date</strong>
                    </label>
                    <br />
                    {
                        <DatePicker
                            className={classNames('form-control')}
                            selected={context.endDate}
                            showTimeSelect={true}
                            onChange={context.handleDateFromFilterChange}
                            dateFormat="yyyy-MM-dd HH:mm"
                        />
                    }
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Available Outside SG Network</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectAvailableOutsideSgNetwork"
                        value={`${context.availableOutsideSgNetwork || ''}`}
                        onChange={context.handleSelectChange}
                        disabled={
                            !userContext.authenticatedUser ||
                            !userContext.authenticatedUser.canAddExternalReader
                        }
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

const EditCreateResourceGeneralInformation = () => {
    const context = React.useContext(CreateResourceContext);
    return (
        <div className="row bg-lvl1">
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Access Level</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectAccessLevel"
                        value={context.accessLevelId}
                        onChange={context.handleSelectChange}
                    >
                        <option value="10">Public</option>
                        <option value="20">Authentificated SGM users</option>
                        <option value="30">
                            Authentificated Internal SGM users
                        </option>
                        <option value="45">User</option>
                        <option value="50">System</option>
                        <option value="60">No Access</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Category</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectCategory"
                        value={context.categoryId}
                        onChange={context.handleSelectChange}
                    >
                        <option value="1">SG Research Website</option>
                        <option value="2">Analytics Applications</option>
                        <option value="3">GRS Distribution Templates</option>
                        <option value="4">GRS Distribution Webcasts</option>
                        <option value="5">GEF Distribution</option>
                        <option value="6">Indicators Pictures</option>
                        <option value="7">Others Files</option>
                        <option value="8">Toolbox Documents</option>
                        <option value="9">IPad User Resources</option>
                        <option value="10">Execution</option>
                        <option value="12">SG Markets Website</option>
                        <option value="13">Authentication Service</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3 p-4">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkUseCache"
                        defaultChecked={context.isCacheable}
                        onChange={context.handleInputChange}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="checkUseCache"
                    >
                        Cache resource
                    </label>
                </div>
            </div>
        </div>
    );
};
