import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'react-app-polyfill/ie11';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { getConfiguration, loadConfiguration } from './core/configuration/configurationLoader';
import { getSGConnect, loadSGConnect } from './core/sgConnect';

import './css/bootstrapTheming.scss';
import './css/site.scss';

const renderApp = () => {
    const container = document.getElementById('root');

    const root = createRoot(container!);
    root.render(App((<App />)));
    const appInitLoader = document.getElementById('app-init-loader');
    if (appInitLoader) {
        appInitLoader.remove();
    }
};

const startup = async () => {
    loadConfiguration(window.location.hostname);
    loadSGConnect(getConfiguration().sgConnect);

    const sgConnect = getSGConnect();
    if (!sgConnect.isAuthorized()) {
        sgConnect.requestAuthorization();
        return;
    }

    const windowIfDefined =
        typeof window === 'undefined'
            ? null
            : ((window as unknown) as Window);
    if (windowIfDefined && !windowIfDefined.sgwtConnect) {
        windowIfDefined.sgwtConnect = sgConnect;
    }

    renderApp();
};

startup();
