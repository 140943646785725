import { AccountCenter } from '../accountCenter/AccountCenter';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';
import sgLogo from 'images/SGLogo.png';
import SgMarketLogo from 'images/sg_market.svg';

export const Header: React.FC = () => {
    return <header className="header position-relative container container-xxl">
        <nav className="navbar">
            <div className="navbar-title">
                <NavLink to="/" className={({ isActive }) => classNames('navbar-title-link', { active: isActive })}                >
                    <img src={sgLogo} width="32" height="32" alt="SG logo" />
                    <div className="navbar-title-divider" />
                </NavLink>
            </div>
            <div className="navbar-content" />
            <div className="d-block d-flex flex-grow-1 mx-3 justify-content-end ">
                <AccountCenter />
            </div>

            <svg className="d-none d-md-block overflow-visible ms-3" width="145" height="32">
                <image xlinkHref={SgMarketLogo} x="0" y="0" height="16" width="145"></image>
                <text className="font-weight-medium" x="5" y="32" height="16" width="145">
                    Content Management
                </text>
            </svg>
        </nav>
    </header>;
};
