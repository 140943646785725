import React, { PropsWithChildren } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Subtract } from '../../core/utility-types';

import {
    NotificationInfoToast,
    NotificationFailedToast,
    NotificationErrorToast,
} from '../layout/notification';

export type NotificationMessage = {
    context: 'add' | 'close' | 'message';
    message?: string;
} & (
        | {
            success: true;
            countSuccess: number;
            countError: number;
            itemsFailed: string[];
        }
        | { success: false }
    );

type NotificationsProviderProps = PropsWithChildren;
type NotificationsProviderState = Readonly<typeof initialState>;
const initialState = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    notify: (notification: NotificationMessage) => { },
};

const { Provider, Consumer } = React.createContext<NotificationsProviderState>(
    initialState,
);

class NotificationsProvider extends React.Component<
    NotificationsProviderProps,
    NotificationsProviderState
> {
    public readonly state: NotificationsProviderState = initialState;

    public handleNotify = (notification: NotificationMessage) => {
        const autoClose = 2000;
        if (notification.success) {
            if (notification.countSuccess > 0) {
                toast.info(
                    <NotificationInfoToast
                        context={notification.context}
                        count={notification.countSuccess}
                    />,
                    {
                        className: 'alert alert-info alert-dismissible mb-2',
                        position: 'bottom-right',
                        autoClose,
                    },
                );
            }

            if (notification.countError > 0) {
                toast.error(
                    <NotificationFailedToast
                        context={notification.context}
                        count={notification.countError}
                        itemMessage={
                            notification.countError === 1
                                ? notification.itemsFailed[0]
                                : undefined
                        }
                    />,
                    {
                        className: 'alert alert-danger alert-dismissible mb-2',
                        position: 'bottom-right',
                        autoClose,
                    },
                );
            }
        } else {
            toast.error(
                <NotificationErrorToast
                    context={notification.context}
                    message={notification.message}
                />,
                {
                    className: 'alert alert-danger alert-dismissible mb-2',
                    position: 'bottom-right',
                    autoClose,
                },
            );
        }
    };

    public render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    notify: this.handleNotify,
                }}
            >
                <ToastContainer
                    closeOnClick={false}
                    closeButton={false}
                    hideProgressBar={true}
                />

                {this.props.children}
            </Provider>
        );
    }
}

export type WithNotifierProps = Pick<NotificationsProviderState, 'notify'>;

const withNotifier = <P extends WithNotifierProps>(
    Component: React.ComponentType<P>,
) =>
    class Notifications extends React.Component<
        Subtract<P, WithNotifierProps>,
        PropsWithChildren
    > {
        public render() {
            return (
                <Consumer>
                    {({ notify }) => (
                        <Component {...(this.props as P)} notify={notify} />
                    )}
                </Consumer>
            );
        }
    };

export { NotificationsProvider, Consumer as Notifier, withNotifier };
