import React, { lazy, Suspense, FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthenticationConsumer, AuthenticationProvider } from './components/contexts/AuthenticationContext';
import { NotificationsProvider } from './components/contexts/NotificationsContext';
import { withErrorBoundary } from './components/errorBoundary/ErrorBoundary';
import { ErrorMessage } from './components/errorBoundary/ErrorMessage';
import { Footer } from './components/layout/footer/Footer';
import { Header } from './components/layout/header/Header';
import { HelpCenter } from './components/layout/helpCenter/HelpCenter';
import { CreatePage } from './components/pages/createPage/CreatePage';
import { DashboardPage } from './components/pages/dashboardPage/dashboardPage';
import UnauthorizedError from './components/pages/error/UnauthorizedError';
import { SearchPage } from './components/pages/searchResourcePage/SearchPage';
import { ServiceLoader } from 'components/common/Loading';
import { SgwtConnect } from 'components/layout/SgwtConnect';
import { SplashScreen } from 'components/layout/SplashScreen';

const EditPage = lazy(() => import('components/pages/editPage/EditPage'));
const NotFound = lazy(() => import('components/pages/error/NotFound'));
const TrackPage = lazy(() => import('components/pages/trackPage/TrackPage'));

const MessageErrorBoundary = withErrorBoundary(ErrorMessage);

const AppRoutes: FC = () =>
    <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
        <Routes>
            <Route path="/" element={<SearchPage />} />
            <Route path="/resources" element={<SearchPage />} />
            <Route path="/edit/:id" element={<EditPage />} />
            <Route path="/track/:id" element={<TrackPage />} />
            <Route path="/create" element={<CreatePage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/error403" element={<UnauthorizedError />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Suspense>;

const Layout: FC = () => {
    return (
        <NotificationsProvider>
            <Header />
            <div className="main-container">
                <div className="container container-xxl mb-5">
                    <Suspense fallback={<ServiceLoader />}>
                        <PageLayoutLoader />
                    </Suspense>
                </div>
            </div>
            <Footer />
            <HelpCenter />
            <SgwtConnect />
            <SplashScreen />
        </NotificationsProvider>
    );
};

const PageLayoutLoader: FC = () => (
    <AuthenticationConsumer>
        {({ isLoading }) => (isLoading && <ServiceLoader />) || <PageLayout />}
    </AuthenticationConsumer>
);

export const PageLayout: FC = () => (
    <AuthenticationConsumer>
        {({ authenticatedUser }) =>
            (authenticatedUser && authenticatedUser.isConnectedFromLan && (
                <>
                    <AppRoutes />
                </>
            )) || <UnauthorizedError />
        }
    </AuthenticationConsumer>
);

const App: FC = () => (
    <MessageErrorBoundary>
        <Router>
            <AuthenticationProvider>
                <AuthenticationConsumer>
                    {authenticationContext =>
                        authenticationContext.authenticatedUser && (
                            <>
                                <Layout />
                            </>
                        )
                    }
                </AuthenticationConsumer>
            </AuthenticationProvider>
        </Router>
    </MessageErrorBoundary>
);

export default App;
