import { configDev } from '../../config.dev';
import { configLocal } from '../../config.local';
import { configPrd } from '../../config.prd';
import { configUat } from '../../config.uat';
import { Configuration } from './configuration';

let config: Configuration = configLocal;

export const loadConfiguration = (hostname: string): Configuration => {
    if (
        hostname === 't-content-ui-dev.sgmarkets.com' ||
        hostname === 'contentmanager-dev.sgmarkets.com'
    ) {
        config = configDev;
        return config;
    }

    if (
        hostname === 't-content-ui-uat.sgmarkets.com' ||
        hostname === 'contentmanager-uat.sgmarkets.com'
    ) {
        config = configUat;
        return config;
    }

    if (
        hostname === 't-content-ui.sgmarkets.com' ||
        hostname === 't-content-ui-preview.sgmarkets.com' ||
        hostname === 'contentmanager.sgmarkets.com' ||
        hostname === 'contentmanager-preview.sgmarkets.com'
    ) {
        config = configPrd;
        return config;
    }

    return config;
};

export const getConfiguration = () => {
    return config || loadConfiguration(window.location.hostname);
};
