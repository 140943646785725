import * as React from 'react';
import { Filters } from '../../../../core/models/contentModels';
import { map } from 'lodash';
import { SearchResourcesContext } from '../../../contexts/SearchResourcesContext';

export const ResourceFilters = ({
    name,
    available,
}: {
    name: string;
    available: Filters[];
}) => {
    const context = React.useContext(SearchResourcesContext);
    return (
        <>
            <label className="font-weight-bold pt-3">{name}</label>
            <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                {map(available, (filters, i) => (
                    <div key={i}>
                        <div className="custom-control custom-checkbox pb-2">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                data-name={filters.name}
                                data-value={filters.id || filters.name}
                                onChange={context.onChangeSelectedFilter(
                                    name,
                                    (filters.id || filters.name).toString(),
                                )}
                                id={`${name}-${filters.id || filters.name}`}
                            />
                            <label
                                className="custom-control-label pr-2 text-nowrap"
                                htmlFor={`${name}-${filters.id ||
                                    filters.name}`}
                            >
                                {filters.name || filters.id}
                                <span className="badge badge-pill badge-secondary ml-2 text-small">
                                    {filters.count}
                                </span>
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
