import * as React from 'react';
import { Error } from './Error';

import image500 from '../../../images/500.svg';

export class UnauthorizedError extends React.PureComponent {
    public render() {
        return (
            <Error
                code={403}
                title="Not Authorized"
                message="You are unauthorized to manage contacts"
                imageUrl={image500}
                isAuthorized={true}
            />
        );
    }
}

export default UnauthorizedError;
