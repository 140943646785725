import { CreateResourceProvider } from '../../contexts/CreateResourceContext';
import { EditCreatePageLayout } from '../shared/EditCreatePageLayout';
import { AuthenticationConsumer } from '../../contexts/AuthenticationContext';
import { Navigation } from '../../common/navigation/Navigation';
import { hasCreationRight } from '../../../core/service/ConnectedUserPrivilege';
import React from 'react';
import UnauthorizedError from '../error/UnauthorizedError';

export const CreatePage = () => (
    <AuthenticationConsumer>
        {({ authenticatedUser }) =>
            authenticatedUser ? (
                hasCreationRight(authenticatedUser) ? (
                    <>
                        <Navigation
                            title="Create Resource"
                            hideCreateButton={true}
                        />
                        <CreateResourceProvider>
                            <EditCreatePageLayout />
                        </CreateResourceProvider>
                    </>
                ) : (
                    <UnauthorizedError />
                )
            ) : (
                <></>
            )
        }
    </AuthenticationConsumer>
);
