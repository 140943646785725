import * as React from 'react';
import { getConfiguration } from '../../../../core/configuration/configurationLoader';
import classNames from 'classnames';
import { Resource } from '../../../../core/models/contentModels';
import mime from 'mime';
import { canEditResource } from '../../../../core/service/ConnectedUserPrivilege';
import { AuthenticationContext } from '../../../contexts/AuthenticationContext';
import { format } from 'date-fns';

const toHSL = (str: string) => {
    let hash = 0;
    if (str.length === 0) return 'hsl(0, 100%, 50%)';
    for (let i = 0; i < str.length; i += 1) {
        hash = (str.charCodeAt(i) + hash * 128) % 0x7fffffff;
    }

    hash = (hash * 0x41a7) % 0x7fffffff;
    const hue = hash % 360;

    hash = (hash * 0x41a7) % 0x7fffffff;
    const light = (hash % 25) + 25;

    return `hsl(${hue}, 100%, ${light}%)`;
};

export const ResourceCard = ({ resource }: { resource: Resource }) => {
    const config = getConfiguration();
    const mimetype = mime.getExtension(resource.mimeType) || 'bin';
    const color = toHSL(mimetype);
    const userContext = React.useContext(AuthenticationContext);
    const canEdit = canEditResource(userContext.authenticatedUser, resource);

    return (
        <div className="product-card col-12 col-xl-6 d-flex">
            <div
                style={{ borderTop: `3px solid ${color}` }}
                className={classNames(
                    'card border-left border-right border-bottom card-raising mb-4 w-100',
                    {
                        'bg-black-alpha-sm': resource.isDeleted,
                    },
                )}
            >
                <div className="card-header pb-0">
                    <span
                        className="text-uppercase text-large"
                        style={{ color }}
                    >
                        {mimetype}
                    </span>
                    <span className="float-right text-primary text-large">
                        {format(resource.creationDate, 'dd/MM/yyyy HH:mm')}
                    </span>
                    <div className="card-title">
                        <a
                            target="blank"
                            href={`${config.serveContentUrl}/${resource.name}`}
                            className="text-xxlarge"
                        >
                            {resource.isDeleted ? (
                                <del className="text-secondary">
                                    {resource.name}
                                </del>
                            ) : (
                                <span className="text-primary">
                                    {resource.name}{' '}
                                    <i className="icon icon-md">open_in_new</i>
                                </span>
                            )}
                        </a>
                    </div>
                    <h6 className="card-subtitle font-weight-extrabold mb-2 text-muted">
                        {resource.deleteDate ? (
                            <>
                                {resource.isDeleted
                                    ? 'Deleted the '
                                    : 'Available until '}
                                {format(resource.deleteDate, 'dd/MM/yyyy HH:mm')}
                            </>
                        ) : null}
                    </h6>
                </div>
                <div
                    className={classNames('card-body', {
                        'pb-0': canEdit,
                    })}
                >
                    {resource.description ? (
                        <div className="font-italic mb-3">
                            {resource.description}
                        </div>
                    ) : null}
                    {resource.confidentialityLevel ? (
                        <p className="mb-0">
                            <span className="text-secondary">
                                Confidentiality Level
                            </span>
                            :{' '}
                            <span className="font-weight-bolder">{`C${resource.confidentialityLevel}`}</span>
                        </p>
                    ) : null}
                    <p className="mb-0">
                        <span className="text-secondary">Category</span>:{' '}
                        <span className="font-weight-bolder">
                            {resource.category}
                        </span>
                    </p>
                    <p className="mb-0">
                        <span className="text-secondary">Access Level</span>:{' '}
                        <span className="font-weight-bolder">
                            {resource.accessLevel}
                        </span>
                    </p>
                    <p className="mb-0">
                        <span className="text-secondary">Owner</span>:{' '}
                        <span className="font-weight-bolder">
                            {resource.ownerEmail || 'No Owner'}
                        </span>
                    </p>
                </div>
                {canEdit && (
                    <div className="card-footer text-right p-0">
                        <a
                            className={`btn ${resource.isDeleted
                                ? 'btn-warning'
                                : 'btn-success'
                                } btn-lg btn-icon-text`}
                            href={`/edit/${resource.name}`}
                        >
                            <i className="icon">edit</i>Edit
                        </a>
                        <a
                            className="btn btn-info btn-lg btn-icon-text m-2"
                            href={`/track/${resource.name}`}
                        >
                            <i className="icon ">track_changes</i>Track
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};
