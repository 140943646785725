import * as React from 'react';
import { SearchResourcesContext } from '../../../contexts/SearchResourcesContext';
import { ResourceCard } from './ResourceCard';
import { map, keys } from 'lodash';
import { Loading } from '../../../loading/Loading';
import { Pager } from '../../../common/pagination/Pager';
import { ResourceFilters } from './ResourceFilters';

export const SearchLayout = () => {
    const context = React.useContext(SearchResourcesContext);

    const callback = React.useCallback(
        (event: any) => {
            context.updateSearch(event.currentTarget.value);
        },
        [context],
    );

    return (
        <div className="pt-5">
            <div className="container">
                <div className="input-group input-group-xl">
                    <div className="input-group-prepend input-group-merged">
                        <i className="icon text-secondary">search</i>
                    </div>
                    <input
                        type="searchText"
                        className="form-control form-control-xl"
                        placeholder="Search for resources"
                        onChange={callback}
                        value={context.search}
                    />
                </div>
                <div className="row mt-5">
                    <div className="col-12 col-md-3">
                        {context.selectedFilters &&
                            context.hasAvailableFilters() && (
                                <>
                                    <h4>Filters</h4>
                                    {map(
                                        keys(context.availableFilters),
                                        (filterName, i) => (
                                            <ResourceFilters
                                                key={i}
                                                available={
                                                    context.availableFilters[
                                                    filterName
                                                    ]
                                                }
                                                name={filterName}
                                            />
                                        ),
                                    )}
                                </>
                            )}
                    </div>
                    <div className="col-12 col-md-9">
                        {context.isLoading ? (
                            <Loading />
                        ) : (
                            <>
                                <Pager
                                    skip={context.skip}
                                    take={context.take}
                                    totalItems={context.count}
                                    onPageChange={(
                                        skip: number,
                                    ) => {
                                        context.updatePager(skip);
                                    }}
                                />
                                <div className="row d-flex">
                                    {map(
                                        context.resources,
                                        (resource, index) => (
                                            <ResourceCard
                                                resource={resource}
                                                key={index}
                                            />
                                        ),
                                    )}
                                </div>
                                <Pager
                                    skip={context.skip}
                                    take={context.take}
                                    totalItems={context.count}
                                    onPageChange={(
                                        skip: number,
                                    ) => {
                                        context.updatePager(skip);
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
