import classNames from 'classnames';
import * as React from 'react';
import { PageItem } from './PageItem';

import './Pager.scss';

export interface IProps {
    skip: number;
    take: number;
    totalItems: number;
    onPageChange: (skip: number, take: number) => void;
}

const numberOfPageItemsToDisplayAroundCurrentPage = 2;

export class Pager extends React.PureComponent<IProps> {
    public render() {
        const currentPage = this.getCurrentPage();
        const pageCount = this.getPageCount();

        const previousPageClasses = classNames('page-item', {
            disabled: currentPage === 0,
        });

        const nextPageClasses = classNames('page-item ml-2', {
            disabled: currentPage >= pageCount - 1,
        });

        return pageCount > 1 ? (
            <ul className="pagination justify-content-center">
                <li className={previousPageClasses}>
                    <button
                        className="page-link"
                        onClick={this.handlePreviousPage}
                    >
                        Previous
                        {/* <KeyboardArrowLeftIcon className='icon' fontSize='inherit' /> */}
                    </button>
                </li>

                {this.renderPages()}

                <li className={nextPageClasses}>
                    <button
                        className="page-link pl-2"
                        onClick={this.handleNextPage}
                    >
                        Next
                        {/* <KeyboardArrowRightIcon className='icon' fontSize='inherit' /> */}
                    </button>
                </li>
            </ul>
        ) : null;
    }

    private renderPages() {
        const pages = this.getPageItemToDisplay();
        return (
            <>
                {pages.map(page =>
                    this.renderPageItemWithEllipsisWhenNeeded(page),
                )}
            </>
        );
    }

    private renderPageItemWithEllipsisWhenNeeded(page: number) {
        const pages = this.getPageItemToDisplay();
        return (
            <React.Fragment key={`fragment-${page}`}>
                {page > 0 &&
                !pages.includes(page - 1) &&
                !pages.includes(page - 2)
                    ? this.renderEllipsis(page - 1)
                    : null}
                {page > 0 &&
                !pages.includes(page - 1) &&
                pages.includes(page - 2)
                    ? this.renderPageItem(page - 1)
                    : null}
                {this.renderPageItem(page)}
            </React.Fragment>
        );
    }

    private renderPageItem(page: number) {
        const currentPage = this.getCurrentPage();
        return (
            <PageItem
                key={`page-${page}`}
                pageNumber={page}
                selected={currentPage === page}
                onPageSelect={this.handlePageSelect}
            />
        );
    }

    private renderEllipsis(page: number) {
        return (
            <li key={`page-ellipsis-${page}`}>
                <div className="page-link page-ellipsis disabled">...</div>
            </li>
        );
    }

    private getCurrentPage(): number {
        const { skip, take } = this.props;
        return Math.floor((skip - 1) / take) + 1;
    }

    private getPageCount(): number {
        const { take, totalItems } = this.props;
        return Math.floor((totalItems - 1) / take) + 1;
    }

    private getPageItemToDisplay() {
        const currentPage = this.getCurrentPage();
        const pageCount = this.getPageCount();
        const pages: number[] = [];
        for (let page = 0; page < pageCount; page += 1) {
            if (page === 0 || page === pageCount - 1) {
                pages.push(page);
                continue;
            }

            if (
                page >=
                    currentPage - numberOfPageItemsToDisplayAroundCurrentPage &&
                page <=
                    currentPage + numberOfPageItemsToDisplayAroundCurrentPage
            ) {
                pages.push(page);
                continue;
            }
        }
        return pages;
    }

    private handlePageSelect = (pageNumber: number) => {
        const { take } = this.props;
        this.handlePageChange(pageNumber * take, take);
    };

    private handlePreviousPage = () => {
        const { take } = this.props;
        const currentPage = this.getCurrentPage();
        this.handlePageChange((currentPage - 1) * take, take);
    };

    private handleNextPage = () => {
        const { take } = this.props;
        const currentPage = this.getCurrentPage();
        this.handlePageChange((currentPage + 1) * take, take);
    };

    private handlePageChange(skip: number, take: number) {
        const { onPageChange } = this.props;
        if (onPageChange) {
            onPageChange(skip, take);
        }
    }
}
