import React from 'react';
import style from './Footer.module.scss';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-mini-footer': any;
        }
    }
}

export const Footer: React.FC = () => {
    return <WidgetLazyLoader script={`${getConfiguration().sharedSgMarketsBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`}>
        <div className={`w-100 ${style.miniFooter}`}>
            <sgwt-mini-footer
                id="sgwt-mini-footer"
                mode="sg-markets"
                contact-us="{'mail':'SGMarkets@sgcib.com'}"
                design="light"
            />
        </div>
    </WidgetLazyLoader>;
};
