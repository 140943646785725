import React from 'react';
import { getConfiguration } from '../../../core/configuration/configurationLoader';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { Navigation } from '../../common/navigation/Navigation';
import { hasAdministratorPrivilege } from '../../../core/service/ConnectedUserPrivilege';
import { Loading } from '../../loading/Loading';
import UnauthorizedError from '../error/UnauthorizedError';

export const DashboardPage = () => {
    const userContext = React.useContext(AuthenticationContext);

    if (userContext.authenticatedUser) {
        const isAdmin = hasAdministratorPrivilege(
            userContext.authenticatedUser,
        );

        return isAdmin ? (
            <>
                <Navigation title="Dashboard" selectedPills="Dashboard" />
                <DashboardLayout />
            </>
        ) : (
            <UnauthorizedError />
        );
    }
    return <Loading />;
};

export const DashboardLayout = ({ shortName }: { shortName?: string }) => {
    const config = getConfiguration();
    return (
        <div className="pt-5 bg-lvl2">
            <div className="container">
                You need to use the following identifiant:
                <br />
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label fixed-input">
                        Login
                    </label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            readOnly={true}
                            className="form-control w100"
                            value="content-user"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label fixed-input">
                        Password
                    </label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            readOnly={true}
                            className="form-control w100"
                            value="content-user"
                        />
                    </div>
                </div>
                <iframe
                    title="Kibana tracking"
                    src={
                        shortName
                            ? generateKibanaTrackUrl(
                                shortName,
                                config.kibanaUrl,
                            )
                            : config.kibanaUrl
                    }
                    style={{ width: '100%', height: '800px' }}
                />
            </div>
        </div>
    );
};

const generateKibanaTrackUrl = (shortName: string, kibanaUrl: string) => {
    return `${kibanaUrl}/s/content/app/kibana#/dashboard/ecb799a0-6ba6-11ea-8777-d1e15783a8e9?_g=(filters:!())&_a=(filters:!(('$state':(store:appState),query:(match:(component.keyword:(query:sgmarkets-api-content,type:phrase)))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'6fddc4b0-6b72-11ea-8777-d1e15783a8e9',key:s-short-name.keyword,negate:!f,params:(query:${shortName}),type:phrase),query:(match:(s-short-name.keyword:(query:${shortName},type:phrase))))),viewMode:view)`;
};
