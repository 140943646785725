export const configDev = {
    environment: 'development',
    sgConnect: {
        authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
        unauthorizationEndpoint: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://pretrade-api-content-uat.fr.world.socgen',
        clientId: '44e9dd34-ef1a-4259-b49f-ee31ff037910',
        scope: 'openid profile mail api.insightuserdomain.v1 api.manage-contacts.v1 api.researchcontentdomain.admin api.researchcontentdomain.user api.researchcontentdomain.v1',
        level: 'L1',
    },
    apiContentUrl: 'https://pretrade-api-content-dev.fr.world.socgen',
    apiContentRequestSizeLimitInMB: 28,
    serveContentUrl: 'https://content-dev.sgmarkets.com',
    kibanaUrl: 'https://3364045e098b447682837a9c4841b039.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sharedSgMarketsBaseUrl: 'https://shared-uat.sgmarkets.com',
};