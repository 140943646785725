import React, { PropsWithChildren } from 'react';
import { logTechnical } from '../../core/logging/logger';

type ErrorBoundaryState = {
    error: Error | null;
};

export type ErrorBoundaryProps = {
    error: Error;
};

export const withErrorBoundary = (
    WrappedComponent: React.ComponentType<ErrorBoundaryProps>,
) => {
    return class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
        public static getDerivedStateFromError(error: Error) {
            return { error };
        }

        constructor(props: PropsWithChildren) {
            super(props);
            this.state = {
                error: null,
            };
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        public componentDidCatch(error: Error | null, info: object) {
            this.setState({ error });

            if (error) {
                logTechnical('error', 'An unknown error has occured', {
                    message: error.message,
                    stack: error.stack || '',
                });
            }
        }

        public render() {
            const { children } = this.props;
            if (this.state.error) {
                return <WrappedComponent error={this.state.error} />;
            }

            return children;
        }
    };
};
